import full01 from '../../../assets/images/fulls/01.jpg'
import full02 from '../../../assets/images/fulls/02.jpg'
import full03 from '../../../assets/images/fulls/03.jpg'
import full04 from '../../../assets/images/fulls/04.jpg'
//import full05 from '../../../assets/images/fulls/05.jpg'
import full06 from '../../../assets/images/fulls/06.jpg'
import full07 from '../../../assets/images/fulls/07.jpg'
import thumb02 from '../../../assets/images/thumbs/02.jpg'
import thumb01 from '../../../assets/images/thumbs/01.jpg'
import thumb03 from '../../../assets/images/thumbs/03.jpg'
import thumb04 from '../../../assets/images/thumbs/04.jpg'
//import thumb05 from '../../../assets/images/thumbs/05.jpg'
import thumb06 from '../../../assets/images/thumbs/06.jpg'
import thumb07 from '../../../assets/images/thumbs/07.jpg'


export const DEFAULT_IMAGES = [
    {
      id: '1',
      source: full01,
      thumbnail: thumb01,
      caption: 'Project Hypertek',
      description: 'E-moto concept with deign legend Pierre Treblanche',
    },
    {
      id: '2',
      source: full02,
      thumbnail: thumb02,
      caption: 'Bultaco Motors',
      description: 'Rebuilding a legend with all-electric propulsion',
    },
    {
      id: '3',
      source: full03,
      thumbnail: thumb03,
      caption: 'Arcimoto',
      description: 'Automotive accessory engineering',
    },
    {
      id: '4',
      source: full04,
      thumbnail: thumb04,
      caption: '2nd Sight Fairpick',
      description: 'Automating agriculture, adding value in data',
    },
   {
     id: '5',
     source: full07,
     thumbnail: thumb07,
     caption: 'D1G1TAL DR1VE',
     description: 'Drop in traction solutions',
   },
    {
      id: '6',
      source: full06,
      thumbnail: thumb06,
      caption: 'Motoczysz Racing',
      description: 'Electric Motorsports Champions',
    }
   // {
    //  id: '7',
     // source: full07,
      //thumbnail: thumb07,
      //caption: 'D1G1TAL DR1VE',
      //description: 'Drop-in traction solution',
   // }
]