import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a href="https://www.instagram.com/nschoeps/" className="icon fa-instagram">
            <span className="label">instagram</span>
          </a>
        </li>
        <li>
          <a href="tel:503-662-8229" className="icon fa-phone">
            <span className="label">Phone</span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/nschoeps/" className="icon fa-linkedin">
            <span className="label">Linkedin</span>
          </a>
        </li>
        <li>
          <a href="mailto: nick@upspundesign.com" className="icon fa-envelope-o">
            <span className="label">Email</span>
          </a>
        </li>
      </ul>
  
    </div>
  </div>
)

export default Footer
