import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle = 'upspun design'
  const siteDescription = 'upspun design'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              My name is Nick  
             </h2>
             <h2>
             I run upspun design
                          
            </h2>
          </header>
          <p>
             At upspun we get shit done -  from prototype to production. Our solutions win races, bring equity to agricultre, and look darn good serving beer. We're ready for your next challenge.

            
            


          </p>
          <ul className="actions">
            <li>
              <a href="https://www.linkedin.com/in/nschoeps/" className="button">
                Learn More
              </a>
            </li>
          </ul>
        </section>

        <section id="two">
          <h2>Recent Projects</h2>

          <Gallery />

          
          {/*<ul className="actions">
            <li>
              <a href="#" className="button">
                Full Portfolio
              </a>
            </li>
  </ul>*/}
        </section>

        <section id="three">
          <h2>Get In Touch</h2>
          <p>
            Challenges are exciting - tell us about yours.
          </p>
          <div className="row">
            {/*}
            <div className="8u 12u$(small)">
              <form method="post" action="#">
                <div className="row uniform 50%">
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="12u">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <ul className="actions" style={{ marginTop: 30 }}>
                  
                  <li>
                    <input type="submit" value="Send Message" />
                  </li>
                </ul>
              </form>
            </div>
            {*/}
            <div className="4u 12u$(small)">
              <ul className="labeled-icons">
                <li>
                  <h3 className="icon fa-home">
                    <span className="label">Address</span>
                  </h3>
                  
                  Portland, OR 
                  <br />
                  United States
                </li>
                <li>
                  <h3 className="icon fa-mobile">
                    <span className="label">Phone</span>
                  </h3>
                  503-662-8229
                </li>
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="mailto:nick@upspundesign.com">nick@upspundesign.com</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
