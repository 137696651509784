import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/MonoBiz.jpg'
//import logo from '../assets/images/upspunlogo.tiff'

const Header = () => (
  <header id="header">
    <div className="inner">
      <a href="#" className="image avatar">
        <img src={avatar} alt="" />
      </a>
      <h1>
        <strong> </strong>   
        <br />
         
        <br />
        
      </h1>
    </div>
    <Footer />
  </header>
)

export default Header
